import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import contactmap from '../../Assets/contact-map.png';
import { FaPhoneVolume } from "react-icons/fa6";
import './style.css';

const ContactForm = () => {
    return (
        <div className='contact-us-root'>
            <div className='contact-form'>
                <Row>
                    <Col className='contact-form-col'>
                        <h5>
                            <span className='first'>{"Get in"}</span>&nbsp;
                            <span className='second'>{"touch"}</span>
                        </h5>
                        <h6>
                            {"Your corporate travel transformation starts here!"}
                        </h6>
                        <Form.Group className="mb-2">
                            <Form.Control id="disabledTextInput" placeholder="Contact name" className="contact-input" />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Control id="disabledTextInput" placeholder="Contact phone" className="contact-input" />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Control id="disabledTextInput" placeholder="Email" className="contact-input" />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Control id="disabledTextInput" placeholder="Address" className="contact-input" />
                        </Form.Group>
                        <Button variant="success" className="submit-button">
                            {"SUBMIT"}
                        </Button>
                        <div className='contact-links'>
                            <div className='contact'>
                                <span className='contact-icon'>
                                    <FaPhoneVolume />
                                </span>
                                <span className="contact-desc">
                                    <span>{"Phone"}</span>
                                    <span>{"04702991584"}</span>
                                </span>
                            </div>

                            <div className='contact'>
                                <span className='contact-icon'>
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99198 3.21454V4.99165L2.39844 8.63329V23.7242H24.7726V8.63329L19.1791 4.99165V3.21454H7.99198ZM9.8565 5.07905H17.3146V12.2458L13.5855 14.6638L9.8565 12.2458V5.07905ZM10.7888 6.94357V8.80808H16.3823V6.94357H10.7888ZM7.99198 7.20577V11.0222L5.04955 9.12855L7.99198 7.20577ZM19.1791 7.20577L22.1215 9.12855L19.1791 11.0222V7.20577ZM10.7888 9.74034V11.6049H16.3823V9.74034H10.7888ZM4.26295 10.8474L13.5855 16.8779L22.9081 10.8474V21.8597H4.26295V10.8474Z" fill="black" />
                                    </svg>
                                </span>
                                <span className="contact-desc">
                                    <span>{"E-MAIL"}</span>
                                    <span>{"rdts.ind@gmail.com"}</span>
                                </span>
                            </div>

                            <div className='contact'>
                                <span className='contact-icon'>
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99198 3.21454V4.99165L2.39844 8.63329V23.7242H24.7726V8.63329L19.1791 4.99165V3.21454H7.99198ZM9.8565 5.07905H17.3146V12.2458L13.5855 14.6638L9.8565 12.2458V5.07905ZM10.7888 6.94357V8.80808H16.3823V6.94357H10.7888ZM7.99198 7.20577V11.0222L5.04955 9.12855L7.99198 7.20577ZM19.1791 7.20577L22.1215 9.12855L19.1791 11.0222V7.20577ZM10.7888 9.74034V11.6049H16.3823V9.74034H10.7888ZM4.26295 10.8474L13.5855 16.8779L22.9081 10.8474V21.8597H4.26295V10.8474Z" fill="black" />
                                    </svg>
                                </span>
                                <span className="contact-desc">
                                    <span>{"ADDRESS"}</span>
                                    <span>{"Chackai Airport Road Trivandram"}</span>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col className='column'>
                        <div className="background"></div>
                        <img src={contactmap} alt="contact-us" className="image" />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ContactForm;
