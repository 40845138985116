import React from 'react';
import './style.css';

const Headings = ({ first, second, color }) => {
    return (
        <div className={color ? 'heading-white' : 'heading-black'}>
            {color ?
                <div style={{ display: 'flex',color:"#fff" }}>
                    <span className='second' style={{color:"#fff"}}>{first}</span>&nbsp;
                    {second &&
                        <span className='first'>
                            {second}
                        </span>
                    }
                </div>
                :
                <div style={{ display: 'flex',color:"#000" }}>
                    <span className='first'>{first}</span>&nbsp;
                    {second &&
                        <span className='second' style={{color:"#000"}}>
                            {second}
                        </span>
                    }
                </div>}
            <div className='heading-divider' />
        </div>
    );
};

export default Headings;