import service1 from "../Assets/service1.png";
import service2 from "../Assets/service2.png";
import service3 from "../Assets/service3.png";
import service4 from "../Assets/service4.png";
import service5 from "../Assets/service5.png";
import choice from "../Assets/choice.png";
import choice1 from "../Assets/choice1.png";
import choice2 from "../Assets/choice2.jfif";
import choice3 from "../Assets/choice3.jpeg";

export const ourServices = [
    {
        id: 1,
        serviceimage: service1,
        heading: "Corporate Commute",
        shortdesc: "Efficient and reliable transport options for corporates, ensuring timely arrivals.",
        desc: ["Corporate transportation choices that are dependable and effective and guarantee on-time arrivals",
            "Our business commute services have been carefully created to guarantee your staff members have access to effective, safe transportation, which will raise their productivity and timeliness. Our skilled drivers, who are well equipped with GPS and live tracking systems, are living examples of our dedication to reliability. These solutions will guarantee that your employees get at their destinations on time since they provide accurate navigation and real-time traffic monitoring. As consumer demand for environmentally friendly vehicles rises, we have introduced electric vehicles (EVs) to our fleet in an effort to further improve our services. These cars provide a more cost-effective, environmentally friendly, and enjoyable substitute that is also more economical. To further ensure on-time deliveries, we make use of real-time route planning and sophisticated scheduling tools. Less delays are achieved by expertly studying traffic conditions and making required route adjustments. Our meticulous planning of the itinerary and time management make sure every trip goes as smoothly and effectively as possible.",
            "RDTS provides corporate commute options that are tailored to accommodate the various demands of any type of organization. Our commitment is to deliver a smooth and dependable transportation experience that enhances your business's performance and employee satisfaction. We achieve this by offering a broad selection of vehicle options, cutting-edge technology, eco-friendly options, and careful route planning."
        ]
    },
    {
        id: 2,
        serviceimage: service2,
        heading: "Custom Fleet Choices",
        shortdesc: "Diverse vehicle options tailored to the specific needs and demands of your business.",
        desc: ["Diverse Vehicle Options Tailored to the Specific Needs and Demands of Your Business",
            "At RDTS, we understand that every business has unique transportation needs, which is why we offer a range of vehicle options to cater to diverse requirements. Our fleet includes 4-seat sedans and 7-seat cars, providing flexibility and choice for our corporate clients. Whether you need a compact vehicle for quick city trips or a spacious car for longer journeys, we have the perfect solution to meet your needs. Every one of our vehicles goes through routine maintenance and quality inspections to guarantee the best possible quality and dependability. Our top-notch maintenance program ensures that our fleet is constantly in excellent shape, giving our customers a dependable and safe travel experience. Since we recognize how crucial dependability is for business travel, we work hard to provide cars that are both exceptionally safe and well- performing.",
            "Our staff at RDTS works closely with clients to comprehend their unique demands and make tailored solutions that best meet their transportation requirements. Our focus on the needs of our clients guarantees that every facet of our service is intended to improve the traveller's entire experience.",
            "Because of our dedication to quality, flexibility, and customer satisfaction, we are the go-to option for companies looking for dependable and effective transportation solutions. Select RDTS for your corporate fleet requirements to see the impact that our customized services can have on our customers. Since we recognize how crucial dependability is for business travel, we work hard to provide cars that are both exceptionally safe and well-performing."
        ]
    },
    {
        id: 3,
        serviceimage: service3,
        heading: "Flexible Rentals",
        shortdesc: "Options available for both short-term and long-term rentals.",
    },
    {
        id: 4,
        serviceimage: service4,
        heading: "Airport Transfer",
        shortdesc: "Premium Solutions for Corporate Travel.",
        desc: ["Premium Solutions for Corporate Travel",
            "Our dedication is to providing exceptional, meticulously tailored airport transportation services that meet the needs of our corporate clients. Our goal is to make sure that arrivals and departures go smoothly. We run a fleet of upscale, air-conditioned vehicles that are designed to be as practical and comfortable as possible.",
            "A key component of our service philosophy is punctuality. Understanding how important it is to arrive and depart on time, we may make the required adjustments to guarantee on-time performance by utilizing these technologies. Our drivers have received considerable training in effective route planning, and they are knowledgeable about the best routes to and from the airport, which improves our ability to provide dependable and timely service.",
            "We provide a complete travel experience in addition to transportation. We have polite, experienced drivers on hand to assist you with your luggage and make sure everything is securely fastened. Our cars are equipped with top-notch conveniences like climate control, cutting-edge audio systems, and plush seating that are all intended to make you feel comfortable.",
            "In terms of airport transfers, RDTS provides a balance of dependability, comfort, and timeliness. Our modern scheduling systems, immaculate fleet, and first-rate in-car amenities provide smooth and trouble-free airport transfers. Select RDTS for the ultimate in luxury and comfort when it comes to airport transportation."
        ]
    },
    {
        id: 5,
        serviceimage: service5,
        heading: "Tour Packages and Services",
        shortdesc: "Memorable Experiences Tailored to Your Needs.",
        desc: ["Memorable Experiences Tailored to Your Needs",
            "Here at RDTS, we provide a wide range of carefully crafted trip packages that are sure to provide both individuals and groups with unforgettable travel experiences. We provide a variety of experiences to suit a wide range of interests and tastes, such as adventure excursions, sightseeing tours, cultural explorations, and corporate retreats. Because every package is completely customisable, you can design itineraries that suit your unique requirements and preferences. Meal preparation, transportation, lodging, and guided excursions are all included in our extensive services, which are all intended to improve your vacation experience.",
            "Our packages guarantee that every detail of your trip is painstakingly planned for your convenience, whether your goal is to take in cultural events, discover local sights, or simply unwind. Through fun and well-organized excursions, our hardworking staff is committed to providing guests with experiences they won't soon forget.",
            "We make it easier to create the perfect travel schedule that suits your interests with our flexible scheduling and customized alternatives. You can be sure of a flawless and enlightening travel experience that upholds the highest standards of excellence when you select RDTS for your next journey, be it a city tour, a spiritual pilgrimage, or a peaceful retreat in Munnar. Our diligent team is dedicated to offering enjoyable, well-planned experiences that provide visitors lifelong memories.",
            "Our easily configurable options and flexible scheduling allow you to plan the ideal trip that fits your interests. You may be sure that RDTS will give you a faultless, rewarding, and first-rate travel experience whether you're arranging a city trip, a peaceful retreat in Munnar, or a spiritual pilgrimage."
        ]
    },
];

export const ourChoices = [
    {
        id: 1,
        choicename: "Hatchback",
        choiceimage: choice
    },
    {
        id: 2,
        choicename: "MPV 7 Seater",
        choiceimage: choice1
    },
    {
        id: 3,
        choicename: "Sedan",
        choiceimage: choice2
    },
    {
        id:4,
        choicename: "Urbania 10 Seater Van",
        choiceimage: choice3
    }
]