import React, { useState } from 'react';
import rdtslogo from "../../Assets/rdtslogo.png";
import './style.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = "7306999099"; // Replace with the desired number

        // Function to detect if the user is on a mobile device
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        // URL for WhatsApp
        const whatsappUrl = isMobile
            ? `https://wa.me/${phoneNumber}` // Mobile URL
            : `https://web.whatsapp.com/send?phone=${phoneNumber}`; // Desktop URL

        // Open the URL in a new tab
        window.open(whatsappUrl, '_blank');
    };

    return (
        <header className="header">
            <nav className="nav">
            <div className='rdts-logo'>
                <img src={rdtslogo} alt="logo" width={100} height={100}/>
                </div>
                <div className="menu-icon" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </div>
                <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <div className="close-icon" onClick={toggleMenu}>
                        &times; {/* Close icon */}
                    </div>
                    <li><a href="/">Home</a></li>
                    <li><a href="/aboutus">About</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contactus">Contact</a></li>
                    <button className="book-now" onClick={handleWhatsAppClick}>
            Book Now
        </button>
                </ul>
            </nav>
        </header>
    );
};

export default Navbar;
