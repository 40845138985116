import React from 'react';
import Header from '../../Components/Header';
import OurServices from '../OurServices';
import OurChoices from '../OurChoices';
import Aboutus from '../Aboutus';
import Footer from '../../Components/Footer';
import './style.css';

const HomePage = () => {
    return (
        <>
            <Header />
            <div className='base-container'>
                <OurServices buttonName={"Book Now"}/>
                <OurChoices />
            </div>
            <Aboutus />
            <Footer />
        </>
    );
};

export default HomePage;