import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../Layouts/HomePage';
import AboutusPage from '../Layouts/AboutusPage';
import OurServicesPage from '../Layouts/OurServicesPage';
import Contactus from '../Layouts/Contactus';
import Service from '../Components/Service';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutusPage />} />
        <Route path="/services" element={<OurServicesPage />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/services/:servicename" element={<Service />} />
      </Routes>
    </Router>
  );
}

export default App;
