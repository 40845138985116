import React from 'react';
import Headings from '../../Components/Headings';
import ChoicesCard from '../../Components/ChoiceCard';
import { ourChoices } from '../../Configurations/contents';
import './style.css';

const OurChoices = () => {
    return (
        <div className="our-choices-container">
            <Headings first={"Custom"} second={"Fleet Choices"} />
            <div className="carousel-container">
                {ourChoices.map((choice, index) => (
                    <ChoicesCard key={index} choiceimage={choice?.choiceimage} choicename={choice?.choicename} />
                ))}
            </div>
        </div>
    );
};

export default OurChoices;
