import React from 'react';
import Headings from '../../Components/Headings';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import './style.css';

const Aboutus = () => {

    const navigate = useNavigate();

    const routeFn = () => {
        navigate(`/aboutus`);
    }

    return (
        <div className='about-us-container'>
            <div className='about-us-content'>
                <Headings first={"About"} second={"Us"} color={true} />
                <div>
                    {'We provide the best rental services to fulfill all of your needs for transit. With a wide range of rental vehicles available, we ensure that all customer requirements are fulfilled. At RDTS, vehicles are well-maintained and equipped with air conditioning, providing a high-quality service to ensure a smooth and comfortable ride. We provide a variety of transportation packages suited for corporate travel, we provide business automobile rentals ranging from one-day visits to long-term rentals to satisfy the specialized transportation demands of customers. Additionally, we offer driver-on-demand services, making your trip comfortable and enjoyable.'}
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='success' className='learn-more-btn' onClick={routeFn}>
                        {"Learn More+"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Aboutus;