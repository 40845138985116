import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Headings from '../../Components/Headings';
import './style.css';
import ServiceCard from '../../Components/Services';
import { ourServices } from '../../Configurations/contents';

const OurServices = ({ buttonName }) => {
    return (
        <div className='our-services-div'>
            <div style={{ paddingBottom: '6.75vw' }}>
                <Headings first={"Our"} second={"Services"} />
            </div>
            <Row>
                {ourServices.map((service, index) =>
                    <Col key={index} xs={12} sm={6} md={4} lg={4} style={{ paddingBottom: '4.375vw' }}>
                        <ServiceCard
                            id={service?.id}
                            heading={service?.heading}
                            serviceimage={service?.serviceimage}
                            shortdescription={service?.shortdesc}
                            desc={service?.desc}
                            buttonName={buttonName} />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default OurServices;
