import React from 'react';
import { ourServices } from '../../Configurations/contents';
import Navbar from '../Navbar';
import Banner from '../Banner';
import Footer from '../Footer';
import './style.css';

const Service = () => {

    const serviceid = localStorage.getItem("serviceid");
    // eslint-disable-next-line
    const service = ourServices?.filter((s) => s?.id == serviceid);
    const servicename = service[0]?.heading?.split(" ")

    return (
        <>
            <Navbar />
            <div className="service-parent">
            {service?.length &&
                <Banner first={servicename && servicename[0]} second={servicename && servicename[1]} />
            }
            {service?.length &&
                <div className='service-root'>
                    <div className='service-breadcrumbs'>
                        <span className='main'>
                            {`Our Service /`}
                        </span>
                        <span className='secondary'>
                            {service[0]?.heading}
                        </span>
                    </div>
                    <div className='service-description'>
                        {service[0]?.desc?.map((s) =>
                            <p>
                                {s}
                            </p>)}
                    </div>
                </div>}
                <Footer/>
                </div>
        </>
    );
};

export default Service;