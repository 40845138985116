import React from 'react';
import map from '../../Assets/map.png';
import youtube from '../../Assets/youtube.svg';
import fb from '../../Assets/fb.svg';
import twitter from '../../Assets/twitter.svg';
import { Row, Col } from 'react-bootstrap';
import './style.css';

const Footer = () => {
    return (
        <footer className="footer">
                {/* First Row */}
                <Row className="footer-row">
                    <Col xs={6} sm={6} md={3} className="footer-col">
                        <ul className='footer-links'>
                            <li>{'Contact'}</li>
                            <br />
                            <li>+470 299 1584</li>
                            <li>+91 73 0699 9099</li>
                            <li>rdts.ind@gmail.com</li>
                        </ul>
                    </Col>
                    <Col xs={6} sm={6} md={3} className="footer-col">
                        <ul className='footer-links'>
                            <li>{'Address'}</li>
                            <br />
                            <li>Chackai Airport Road</li>
                            <li>Trivandrum</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="footer-col map-col">
                        <img src={map} alt="map" className="footer-map"/>
                    </Col>
                </Row>
                {/* Second Row */}
                <Row className="footer-row">
                    <Col xs={12} md={{ span: 4, offset: 0 }} className="footer-col social-icons">
                        <img src={youtube} alt="YouTube" className="icon" />
                        <img src={fb} alt="Facebook" className="icon" />
                        <img src={twitter} alt="Twitter" className="icon" />
                    </Col>
                </Row>
                {/* Copyright Row */}
                <div style={{ marginTop: '3.75rem', height: '1px', background: '#fff', width: '100%' }} />
                <Row className="footer-row">
                    <Col className="text-center">
                        <p className="copyright">© 2024 RDTS. All rights reserved.</p>
                    </Col>
                </Row>
        </footer>
    );
};

export default Footer;
