import React, { useEffect } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import About from '../../Components/About';
import './style.css';

const AboutusPage = () => {

    useEffect(()=>{
       window.scroll(0,0) 
    },[])

    return (
        <>
            <Navbar />
            <div className='about-parent'>
            <div className='about-us-banner' />
            <div className='about-container'>
                <About />
                {/* <Headings first={""} second={"Founders"} />
            <Row style={{paddingTop:'9.125rem',width:'100%'}}>
                {[0, 1, 2, 3].map(() =>
                    <Col xs={12} md={3}>
                        <Founder foundername={"Name"} founderimage={founder} founderdesc={"Options available for both short-term and long-term rentals."} />
                    </Col>
                )}
            </Row> */}
            </div>
            <Footer />
            </div>
        </>
    );
};

export default AboutusPage;