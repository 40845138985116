import React from 'react';
import './style.css';

const Banner = ({ first, second }) => {
    return (
        <div className="banner">
            <div className="banner-overlay">

                <div className="banner-text">
                    {first && <span className="first-word">{first}</span>}
                    {second && <span className="rest-words">{second}</span>}
                </div>
            </div>
        </div>
    );
};

export default Banner;
