import React from 'react';
import Navbar from '../../Components/Navbar';
import ContactForm from '../../Components/ContactForm';
import Footer from '../../Components/Footer';
import './style.css';

const Contactus = () => {
  return (
    <>
      <Navbar />
      <div className='contact-us-parent'>
        <ContactForm />
        <Footer />
      </div>
    </>
  );
};

export default Contactus;