import React, { useState } from 'react';
import rdtslogo from "../../Assets/rdtslogo.png";
import './style.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleWhatsAppClick = () => {
        const phoneNumber = "7306999099"; // Replace with the desired number

        // Function to detect if the user is on a mobile device
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        // URL for WhatsApp
        const whatsappUrl = isMobile
            ? `https://wa.me/${phoneNumber}` // Mobile URL
            : `https://web.whatsapp.com/send?phone=${phoneNumber}`; // Desktop URL

        // Open the URL in a new tab
        window.open(whatsappUrl, '_blank');
    };

    return (
        <header className="home-header">
            <video autoPlay muted loop className="home-background-video">
                <source src={require('../../Assets/bannerVideo.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <nav className="home-nav">
                <div className='rdts-logo'>
                <img src={rdtslogo} alt="logo" width={100} height={100}/>
                </div>
                <div className="home-menu-icon" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </div>
                <ul className={`home-nav-links ${isOpen ? 'open' : ''}`}>
                    <div className="home-close-icon" onClick={toggleMenu}>
                        &times; {/* Close icon */}
                    </div>
                    <li><a href="/">Home</a></li>
                    <li><a href="/aboutus">About</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contactus">Contact</a></li>
                    <button className="book-now" onClick={handleWhatsAppClick}>Book Now</button>
                </ul>
            </nav>
            <div className="home-hero-content">
                <div className="hero-heading">We provide the best rental services to fulfill all of your needs for transit.</div>
                <div className="hero-content">
                    With a wide range of rental vehicles available, we ensure that all customer requirements are fulfilled. At RDTS, vehicles are well-maintained and equipped with air conditioning, providing a high-quality service to ensure a smooth and comfortable ride. We provide a variety of transportation packages suited for corporate travel, we provide business automobile rentals ranging from one-day visits to long-term rentals to satisfy the specialized transportation demands of customers. Additionally, we offer driver-on-demand services, making your trip comfortable and enjoyable.
                </div>
                <button className="home-book-now" onClick={handleWhatsAppClick}>Book Now</button>
            </div>
        </header>
    );
};

export default Header;
