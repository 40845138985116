import React from 'react';
import './style.css';

const ChoicesCard = ({ choiceimage, choicename }) => {
    return (
        <div className='choices-card'>
            <div className='choices-image'>
                <img src={choiceimage} alt="hatchback" />
            </div>
            <div className='choice-name'>
                {choicename}
            </div>
        </div>
    );
};

export default ChoicesCard;