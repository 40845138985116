import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ServiceCard from '../../Components/Services';
import Navbar from '../../Components/Navbar';
import Banner from '../../Components/Banner';
import Headings from '../../Components/Headings';
import Footer from '../../Components/Footer';
import { ourServices } from '../../Configurations/contents';
import './style.css';

const OurServicesPage = () => {

    return (
        <>
            <Navbar />
            <div className='service-parent'>
                <Banner />
                <div className="our-servicescontainer">
                    <Headings first={"Our"} second={"Services"} />
                    <Row style={{ paddingTop: '9.375vw' }}>
                        {ourServices.map((service, index) =>
                            <Col key={index} xs={12} sm={6} md={4} lg={4} style={{ paddingBottom: '3.25vw' }}>
                                <ServiceCard
                                    id={service?.id}
                                    heading={service?.heading}
                                    serviceimage={service?.serviceimage}
                                    shortdescription={service?.shortdesc}
                                    desc={service?.desc}
                                    buttonName={"More"} />
                            </Col>
                        )}
                    </Row>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default OurServicesPage;
